import { Config, PlayerError } from "@types";
import { BASE_URL_ENV, PLACEMENTS_API_ROOT } from "./constants";

export const loadScript = (url: string, iframeWindow: Window) => {
  return new Promise((resolve) => {
    const { document } = iframeWindow;
    const script = document.createElement("script");
    script.src = url;
    document.querySelector("head").appendChild(script);
    script.addEventListener("load", () => {
      resolve(true);
    });
  });
}

export const logError = async (error: PlayerError, config: Config) => {
  if (!error.message) return;
  let stack = error.stack;
  if (stack.length > 6000) {
    const playerError = JSON.parse(stack);
    playerError.tag = playerError.tag.slice(0, 300) + "...";
    stack = JSON.stringify(playerError);
  }
  const fetchClientError = async () => {
    const result = await fetch(`${PLACEMENTS_API_ROOT}/clientLog`, {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "type": error.name || "Error",
        "publisherId": config.publisherId,
        "placementId": config.id,
        "domain": window.top.location.hostname,
        "location": window.top.location.href,
        "error": error.message,
        "stackTrace": stack,
      })
    })
    return result;
  }
  const response = await fetchClientError();
  if (response.status === 400) {
    error.stack = error.stack.slice(0, 6000);
    fetchClientError();
  }
  console.error("mia: %cError", "color: #ff0000", error);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const logConsole = (message: string, params?: any[]) => {
  if (BASE_URL_ENV === "prod" && new URLSearchParams(window.location.search).get("mia_debug") !== "true") return;
  console.log(`%cmia: ${message}`, "color: #1890ff", ...params);
}

export const tracking = (event: string, publisherId: string, placementId: string) => {
  logConsole("Logger initialized", [event, publisherId, placementId]);
  try {
    fetch(`${PLACEMENTS_API_ROOT}/tracking/${publisherId}/${placementId}/${event}`);
  } catch (error) {
    logConsole("Logger error", error);
  }
}

export const throttle = (limit: number, callback: () => void) => {
  let waiting = false;
  return function () {
    if (!waiting) {
      callback();
      waiting = true;
      setTimeout(() => {
        waiting = false;
      }, limit);
    }
  }
}